import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"
import Image from "../components/image-map"
import SEO from "../components/seo"
import logo from "../images/logo.png"

const IndexPage = () => (
  <Layout>
    <SEO title="District Selection" />
    <div className="district-select">
      <div className="entry">
      <div className="big">
        <h1 className="title is-size-1">Select your district</h1>
        <p>Use the map below to select your district.</p>
      </div>
      <Link className="circle-button hays-1-button" to="/hwcid-1/">Hays WCID 1</Link>
      <Link className="circle-button hays-2-button" to="/hwcid-2/">Hays WCID 2</Link>
      <Image />
      </div>
      <Footer district="-1"></Footer>
    </div>
  </Layout>
)

export default IndexPage
